import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        

        <h1>行者1911 — Walker 1911</h1>
        <p>我们终将追寻先祖的足迹，再次撕碎这片虚伪的天空。<br />民国重生的典礼之时，铁血十八星旗会重新飘扬在三镇的大街小巷。</p>
      
      </header>

      <main className="App-main">
        <p>Youtube: <a className="App-link" href="https://youtube.com/@Walker-1911">youtube.com/@Walker-1911</a></p>
        <p>Twitter/X: ‪<a className="App-link" href="https://twitter.com/walkerchu1911">twitter.com/walkerchu1911</a></p>
        <p>博客： <a className="App-link" href="https://walker1911.substack.com">walker1911.substack.com</a></p>
        <p>Github: <a className="App-link" href="https://github.com/WalkerChu1911">github.com/WalkerChu1911</a></p>
      </main>

      <footer className="App-footer">
        
        <p>
          <a className="App-footer-link" href="https://walker1911.me">Walker 1911</a>,&nbsp;
          <a className="App-footer-link" href="https://capital1911.org">Capital 1911</a>
          {/* <br></br> */}
        </p>

        <p>
          © Walker 1911 2024. All rights reserved.
        </p>
        
      </footer>
      
    </div>
  );
}

export default App;
