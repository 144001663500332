// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header,
.App-footer {
  background-color: #0061fe;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fefcdd;
}

.App-header {
  min-height: 25vh;
}

.App-footer {
  /* margin-top: auto; */
  min-height: 15vh;

  /* background-color: #0061fe; */
}

.App-footer-link {
  color: #fff2d5;
}


.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1; /* This will make sure the element takes up all available space */

  min-height: 60vh;
  
  background-color: #fefcdd;

  font-size: 1.2em;
  
  color: #012f7b;
}


.App-link {
  color: #3a87fe;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;;EAEE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;;EAEtB,kBAAkB;AACpB;;AAEA;;;GAGG;;AAEH;;;;GAIG;;AAEH;;EAEE,yBAAyB;EACzB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;;EAEhB,+BAA+B;AACjC;;AAEA;EACE,cAAc;AAChB;;;AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAO,EAAE,iEAAiE;;EAE1E,gBAAgB;;EAEhB,yBAAyB;;EAEzB,gBAAgB;;EAEhB,cAAc;AAChB;;;AAGA;EACE,cAAc;AAChB;;AAEA;;;;;;;GAOG","sourcesContent":["html,\nbody {\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\n\n#root\n.App {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  \n  text-align: center;\n}\n\n/* .App-logo {\n  height: 40vmin;\n  pointer-events: none;\n} */\n\n/* @media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n} */\n\n.App-header,\n.App-footer {\n  background-color: #0061fe;\n  /* min-height: 100vh; */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: #fefcdd;\n}\n\n.App-header {\n  min-height: 25vh;\n}\n\n.App-footer {\n  /* margin-top: auto; */\n  min-height: 15vh;\n\n  /* background-color: #0061fe; */\n}\n\n.App-footer-link {\n  color: #fff2d5;\n}\n\n\n.App-main {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  flex: 1; /* This will make sure the element takes up all available space */\n\n  min-height: 60vh;\n  \n  background-color: #fefcdd;\n\n  font-size: 1.2em;\n  \n  color: #012f7b;\n}\n\n\n.App-link {\n  color: #3a87fe;\n}\n\n/* @keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
